<template>
  <div class="page page__ueber">
    <HeaderOne
      :imgpath="'assets/img/03_reservieren/header/21_06_07_THI_1171.jpg'"
      :imgposition="'center 60%'"
    />
    <IntroBlockReservieren class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderOne from "@/components/common/header/HeaderOne";
import IntroBlockReservieren from "@/components/common/introblock/IntroBlockReservieren.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";

export default {
  components: {
    HeaderOne,
    IntroBlockReservieren,
    TheFooter,
  },

  setup() {},
};
</script>
<style lang="scss">
.page_iframe {
  height: auto;
}

#test {
  width: 100%;
  min-height: 900px;
}
</style>
